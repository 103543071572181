import * as React from "react";
import Cta from "../components/cta";
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Video from "../assets/img/MBMB-Photo-01.mp4";
import Layout from "../components/layout";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout backgroundColor="#FFDD00">
    <Seo title="Home" />

    <div className="video-container">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="video"
        width="1425"
        height="2025"
      >
        <source src={Video} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </div>

    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}

    <h1>Welcome</h1>

    <p>
      You are invited to the wedding of
      <br />
      <strong>Monica Bravo + Micah Barrett</strong>
    </p>

    <p>Saturday, October 2, 2021</p>

    <p>
      The Firehouse at Fort Mason
      <br />
      Ceremony at 4:30 pm
      <br />
      Cocktail reception, dinner, and dancing to follow at the same address,
      5–10 pm
    </p>

    <p>Young children are welcome.</p>

    <p>Kindly RSVP by September 2</p>

    <Cta url="/location" text="Learn about the venue" />
  </Layout>
);

export default IndexPage;
